<template>
  <ContainerHub class="hub-contact-details">
    <!-- Header Slot -->
    <template slot="header">
      <HubHeader
        :backBtn="true"
        :title="title"
        :titleTag="'h1'"
        :description="description"
        :email="email"
      />
    </template>

    <!-- Body Slot -->
    <template slot="body">
      <CardUser v-if="currentItem" :content="currentItem" />
      <UserDetails v-if="currentItem" :content="currentItem" />
    </template>
  </ContainerHub>
</template>

<script>
import { mapGetters } from 'vuex'
import ContainerHub from '@containers/ContainerHub'
import HubHeader from './HubHeader'
import CardUser from '@cards/user/CardUser'
import UserDetails from '@blocks/user/UserDetails'

export default {
  name: 'HubContactDetails',
  props: {
    name: {
      type: String,
      required: true
    },
    backBtn: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters({
      title: 'page/getTitle',
      description: 'page/getDescription',
      email: 'page/getEmail',
      items: 'users/getItems'
    }),
    displayType () {
      return 'large'
    },
    currentItem () {
      return this.items[0]
    }
  },
  components: {
    HubHeader,
    ContainerHub,
    CardUser,
    UserDetails
  }
}
</script>

<style lang="scss">
  .hub-page{

  }
</style>
