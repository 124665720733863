<template>
  <div class="user-details">
    <!-- COMPANY -->
    <div class="user-detail">
      <HubHeader
        class="user-details__title"
        :titleTag="'h3'"
        :title="$t('message.users.company.title')" />

      <div class="user-details__content">
        <div class="user-details__group" v-if="content.company">
          <div class="user-details__label">{{ $t(`message.users.company.name`) }}</div>
          <div class="user-details__data">{{ content.company }}</div>
        </div>

        <div class="user-details__group" v-if="content.function">
          <div class="user-details__label">{{ $t(`message.users.company.function`) }}</div>
          <div class="user-details__data">{{ content.function }}</div>
        </div>

        <div class="user-details__group" v-if="content.department">
          <div class="user-details__label">{{ $t(`message.users.company.department`) }}</div>
          <div class="user-details__data">{{ content.department }}</div>
        </div>
      </div>
    </div>

    <!-- ADDRESS -->
    <div class="user-detail">
      <HubHeader
        class="user-details__title"
        :titleTag="'h3'"
        :title="$t('message.users.address.title')" />

      <div class="user-details__content">
        <div class="user-details__group" v-if="content.mailingAddress">
          <div class="user-details__label">{{ $t(`message.users.address.mailingAddress`) }}</div>
          <div class="user-details__data">{{ content.mailingAddress }}</div>
        </div>

        <div class="user-details__group" v-if="content.mailingAddressBox">
          <div class="user-details__label">{{ $t(`message.users.address.mailingAddressBox`) }}</div>
          <div class="user-details__data">{{ content.mailingAddressBox }}</div>
        </div>

        <div class="user-details__group" v-if="content.mailingAddressCity">
          <div class="user-details__label">{{ $t(`message.users.address.mailingAddressCity`) }}</div>
          <div class="user-details__data">{{ content.mailingAddressCity }}</div>
        </div>

        <div class="user-details__group" v-if="content.mailingAddressZip">
          <div class="user-details__label">{{ $t(`message.users.address.mailingAddressZip`) }}</div>
          <div class="user-details__data">{{ content.mailingAddressZip }}</div>
        </div>

        <div class="user-details__group" v-if="content.mailingAddressCountry">
          <div class="user-details__label">{{ $t(`message.users.address.mailingAddressCountry`) }}</div>
          <div class="user-details__data">{{ content.mailingAddressCountry }}</div>
        </div>
      </div>
    </div>

    <!-- TELEPHONE -->
    <div class="user-detail">
      <HubHeader
        class="user-details__title"
        :titleTag="'h3'"
        :title="$t('message.users.telephone.title')"
      />

      <div class="user-details__content">
        <div class="user-details__group" v-if="content.phone">
          <div class="user-details__label">{{ $t(`message.users.telephone.phone`) }}</div>
          <div class="user-details__data">{{ content.phone }}</div>
        </div>

        <div class="user-details__group" v-if="content.fax">
          <div class="user-details__label">{{ $t(`message.users.telephone.fax`) }}</div>
          <div class="user-details__data">{{ content.fax }}</div>
        </div>

        <div class="user-details__group" v-if="content.mobilePhone">
          <div class="user-details__label">{{ $t(`message.users.telephone.mobile`) }}</div>
          <div class="user-details__data">{{ content.mobilePhone }}</div>
        </div>

        <div class="user-details__group" v-if="content.email">
          <div class="user-details__label">{{ $t(`message.users.telephone.email`) }}</div>
          <div class="user-details__data">
            <a :href="`mailto:${ content.email }`">{{ content.email }}</a>
          </div>
        </div>

        <div class="user-details__group" v-if="content.mailingAddressCountry">
          <div class="user-details__label">{{ $t(`message.users.telephone.online`) }}</div>
          <div class="user-details__data">{{ content.mailingAddressCountry }}</div>
        </div>
      </div>
    </div>

    <!-- ASSISTANT -->

    <div class="user-detail">
      <HubHeader
        class="user-details__title"
        :titleTag="'h3'"
        :title="$t('message.users.assistant.title')"
      />

      <div class="user-details__content">
        <div class="user-details__group" v-if="content.assistantName">
          <div class="user-details__label">{{ $t(`message.users.assistant.name`) }}</div>
          <div class="user-details__data">{{ content.assistantName }}</div>
        </div>

        <div class="user-details__group" v-if="content.assistantPhone">
          <div class="user-details__label">{{ $t(`message.users.assistant.telephone`) }}</div>
          <div class="user-details__data">{{ content.assistantPhone }}</div>
        </div>

        <div class="user-details__group" v-if="content.assistantFax">
          <div class="user-details__label">{{ $t(`message.users.assistant.fax`) }}</div>
          <div class="user-details__data">{{ content.assistantFax }}</div>
        </div>

        <div class="user-details__group" v-if="content.assistantEmail">
          <div class="user-details__label">{{ $t(`message.users.assistant.email`) }}</div>
          <div class="user-details__data">
            <a :href="`mailto:${ content.assistantEmail }`">{{ content.assistantEmail }}</a>
          </div>
        </div>
      </div>
    </div>

    <!-- GROUPS -->
    <div class="user-detail user-detail--table" v-if="content.workgroups.length">
      <HubHeader
        class="user-details__title"
        :titleTag="'h3'"
        :title="$t('message.users.belongToGroup.title')"
      />

      <ElementTable v-if="content.workgroups">
        <template slot="header">
          <GroupTableHeader/>
        </template>
        <template slot="body">
          <GroupTableBody
            v-for="(item, index) in content.workgroups"
            :content="item"
            :key="`group-${index}`"
          />
        </template>
      </ElementTable>
    </div>
  </div>
</template>

<script>
import HubHeader from '@blocks/hubs/HubHeader'
import GroupTableHeader from '@blocks/groups/GroupTableHeader'
import GroupTableBody from '@blocks/groups/GroupTableBody'
import ElementTable from '@elements/ElementTable'

export default {
  name: 'UserDetails',
  components: {
    HubHeader,
    ElementTable,
    GroupTableHeader,
    GroupTableBody
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {}
}
</script>

<style lang="scss">
.user-details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 1.4rem;

  &__title{
    margin-bottom: 1rem;
  }

  // Detail

  .user-detail {
    @media print {
      width: 49%;
      &--table {
        width: 100%;
      }
    }
    @include media("small-medium") {
      width: 100%;
    }
    @include media("large") {
      width: 49%;
      &--table {
        width: 100%;
      }
    }
    padding-bottom: layout("spacing");
  }

  // Group
  &__group {
    @media print {
      background-color: lightgray;
    }
    display: flex;
    margin-bottom: 1rem;
    padding: layout("spacing") / 2 layout("spacing");
    background-color: tint(black, 96%);
    border-radius: 4px;
  }

  // Label

  &__label {
    margin-right: 2rem;
    width: 20%;
    color: tint(black, 50%);
  }
}
</style>
