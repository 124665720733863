<template>
  <div class="row">

    <div class="row__group row__group--main">
      <div class="row__label">
        <ElementIconText icon="workgroups" :text="$t('message.contact.table.heading.expertGroups')" />
      </div>
      <div class="row__info">
        <div class="row__info__base">
          <a :href="content.url">{{ content.name }}</a>
        </div>
        <a href="javascript:void(0);" class="row__item__trigger" @click.prevent.stop="handleClick" v-if="!isDesktop">
          <BaseIcon :type="icon" />
        </a>
      </div>
    </div>

    <div class="row__group row__group--secondary" v-show="showInfos">
      <div class="row__item">
        <div class="row__label">
          <ElementIconText icon="pencil" :text="$t('message.contact.table.heading.role')" />
        </div>
        <div class="row__info">
          {{ content.role }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseIcon from '@base/BaseIcon'
import ElementIconText from '@elements/ElementIconText'
import ElementRow from '@elements/ElementRow'
export default {
  name: 'GroupTableBody',
  extends: ElementRow,
  components: {
    BaseIcon,
    ElementIconText
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      showInfos: true
    }
  },
  computed: {
    ...mapGetters({
      device: 'breakpoints/getDevice'
    }),
    icon () {
      return !this.showInfos ? 'chevron-down' : 'chevron-up'
    },
    isDesktop () {
      return this.device === 'desktop'
    }
  },
  watch: {
    isDesktop () {
      this.showInfos = true
    }
  },
  methods: {
    handleClick () {
      this.showInfos = !this.showInfos
    }
  }
}
</script>

<style lang="scss">

</style>
