<template>
  <div class="contacts-user-detail-view">
    <HubContactDetails name="contact-detail" />
  </div>
</template>

<script>
import HubContactDetails from '@blocks/hubs/HubContactDetails'
export default {
  name: 'ContactsUserDetailsView',
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  components: {
    HubContactDetails
  }
}
</script>

<style lang="scss">

</style>
