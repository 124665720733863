<template>

    <div class="row--header" v-if="isDesktop">

      <div class="row__group row__group--main">
        <div class="row__label">
          <a href="javascript:void(0)">
            <ElementIconText icon="workgroups" :text="$t('message.contact.table.heading.expertGroups')" />
          </a>
        </div>
      </div>

      <div class="row__group row__group--secondary">
        <div class="row__item">
          <div class="row__label">
            <a href="javascript:void(0)">
              <ElementIconText icon="pencil" :text="$t('message.contact.table.heading.role')" />
            </a>
          </div>
        </div>
      </div>
    </div>

</template>

<script>
import { mapGetters } from 'vuex'
import ElementRow from '@elements/ElementRow'
import ElementIconText from '@elements/ElementIconText'
export default {
  name: 'GroupTableHeader',
  extends: ElementRow,
  components: {
    ElementIconText
  },
  props: {
    content: {
      type: Object,
      required: false
    },
    active: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters({
      device: 'breakpoints/getDevice'
    }),
    isDesktop () {
      return this.device === 'desktop'
    }
  },
  watch: {

  },
  methods: {

  }
}
</script>

<style lang="scss">

</style>
